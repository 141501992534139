import React, {useState, useEffect} from "react";
import "./Team.css";
import volna from "../../images/volna.png";
import star from "../../images/Star.png";

function Team({employees}) {



  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % employees.length);
  };
  const [cardsToShow, setCardsToShow] = useState(3); // Изначально показываем три карточки

  useEffect(() => {
    const handleResize = () => {
      // Изменяем количество карточек в зависимости от ширины экрана
      if (window.innerWidth < 820) {
        setCardsToShow(1);
      } else {
        setCardsToShow(3);
      }
    };

    window.addEventListener("resize", handleResize); // Слушаем изменения размера экрана
    return () => {
      window.removeEventListener("resize", handleResize); // Отписываемся от слушателя при размонтировании
    };
  }, []);
  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? employees.length - 3 : prevIndex - 1
    );
  };

  return (
    <section className="team">
      <span className="team__span">Rivne 2023</span>
      <div className="team__info">
        <div className="team__title-container">
          <h2 className="team__title">команда з досвідом 10 років</h2>
          <img className="team__title-img" alt="volna" src={volna} />
        </div>
        <div className="team__text-container">
          <p className="team__text">
            Ми точно знаємо що вам треба швидко гарно і не за всі гроші світу
            тож ви у потрібних руках 😎
          </p>
          <p className="team__text">
            Обговоримо проект, подивимося конкурентів, виявимо чим ви кращі за
            інших і будемо робити ставку на це, бо вам треба заробити трошки
            більше грошей 💰 ніж у конкурентів
          </p>
          <p className="team__text">
            Що нам з цього? Ми просто хочемо сильне круте портфоліо щоб мати ще
            більше таких гарнючих клієнтів як ти. Назовемо це - здоровий егоізм
            ❤️{" "}
          </p>
        </div>
      </div>
      <div className="team__button-container">
        <button className="team__button-back" onClick={handlePrev} />
        <button className="team__button-next" onClick={handleNext} />
      </div>

      <ul className="team__table">
        {employees.slice(0, cardsToShow).map((employee, index) => (
          <li key={index} className="team__card">
            <p className="team__card-person">
              {employees[(startIndex + index) % employees.length].name}
            </p>
            <img
      className="team__card-photo"
      src={employees[(startIndex + index) % employees.length].image}
      alt={employees[(startIndex + index) % employees.length].name}
    />
            <p className="team__card-info">
              {employees[(startIndex + index) % employees.length].position}
            </p>
          </li>
        ))}
      </ul>
      <div className="team__star">
        <img className="team__img-star" alt="star" src={star} />
        <img className="team__img-star" alt="star" src={star} />
      </div>
    </section>
  );
}

export default Team;

