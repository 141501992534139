import foto from "../images/MariaNew.svg";
import foto1 from "../images/urii1.svg";
import foto2 from "../images/bogdan1.svg";
import foto3 from "../images/oleksandr.svg";
import foto4 from "../images/olena.svg";


const employees = [
  {
    name: "Марія Іволгіна",
    position: "Менеджер",
    image: foto
  },
  {
    name: "Юрій Коток",
    position: "Дизайнер",
    image:foto1
  },
  {
    name: "Богдан Моргерштен",
    position: "Розробник",
    image:foto2
  },
  {
    name: "Олександр Шевченко",
    position: "Маркетінг",
    image:foto3
  },
  {
    name: "Олена Мамчур",
    position: "Копірайт",
    image:foto4
  },

];

const feedbacks = [
  {
    text: "Ніколи не думал що запустити вебсайт буде так швидко та легко. Вже на першому зідзвоні допомогли визначитися зі стилістикою інтернет магазину, та вже через тиждень сайт вже запустили онлайн. Наразі вже маю перші замовлення з сайту. Дякую дуже агенції за поради та швидкий запуск.",
    author: "Олег"
  },
  {
    text: "В мене був дуже обмежений бюджет, але хотілося щоб сайт візитка виглядала професійно і солідно. Хлопці з BRODRAW показали підходящі лендінги які я можу отримати на свій бюджет, а також зробили знижку із промо кодом 1000 грн. Тож раджу їх із задоволенням вони реально розуміють що треба клієнту і не впарюють незрозумілі космічні рішення за купу грошей",
    author: "Ірина"
  },
  {
    text: "Працюють швидко і розуміють як створити вебсайт який буде дуже виділяти вас серед конкурентів. Вони показали мені конкурентів у моїй ніші яких я взагалі не знав. На базі підбірки конкурентів зробили висновок в чому має бути унікальність мого лендінгу та за тиждень вже мав свій сайт. Задоволений що маю унікальній сайт, кожного разу отримую позитивні відгуки від клієнтів, які кажуть що подзвонили мені спираючись на професійній вигляд мого лендінгу",
    author: "Влад"
  }
];

export { employees, feedbacks };
