import React from "react";
import "./Promotion.css";
import volna from "../../images/volna.png";
import beNormal from "../../images/Be.svg";
import beHovered from "../../images/BeHoveredsvg.svg";
//import ball from "../../images/ball.svg";
import btnImageNormal from "../../images/Iconex/Filled/Logout.svg";
import btnImageHover from "../../images/Logoutactive.svg";

function Promotion(props) {
  const btnImage = props.isHovered ? btnImageHover : btnImageNormal;
  const beImage = props.isHovered ? beHovered : beNormal;

  return (
    <section className="promotion" id="promotion">
      <div className="promotion__container">
        <div className="promotion__par-container">
          <p className="promotion__span">подивися, що ми вміємо</p>
          <p className="promotion__par">
            Ми кайфуємо коли бачимо щасливих клієнтів бо вони отримують більше
            замовлень з сайтів які ми для них створюємо. Вже не дочекаємося коли
            зробимо і для тебе новенький веб-сайт що продає
            <span id="smile"> 💸 💸 💸</span>
          </p>
        </div>
        <img className="promotion__title-img" alt="volna" src={volna} />
        <div className="promotion__title-container">
          <h2 className="promotion__title">Веб-Сайти які ми створили</h2>
          <ul className="promotion__table">
            <li className="promotion__item">
              <img className="promotion__be-img" alt="logo" src={beImage} />
              <div
                className="promotion__link"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <a
                  className="promotion__link-name"
                  href="https://www.behance.net/yurakotok"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Behance
                </a>
                <img
                  className="promotion__link-img"
                  alt="door"
                  src={btnImage}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Promotion;
