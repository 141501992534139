import React, { useState } from "react";
import "./Form.css";
import googleImg from "../../images/link-google.png";
import union from "../../images/Union-form.svg";
import elipse from "../../images/Ellipse-form.svg";
import volna from "../../images/volna.png";
import { NavLink } from "react-router-dom";

function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handlePhoneFocus = () => {
    setIsPhoneFocused(true);
  };
  const handlePhoneInput = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    setPhone(input);
  };

  const handlePhoneBlur = () => {
    setIsPhoneFocused(false);
  };

  const handleMessageFocus = () => {
    setIsMessageFocused(true);
  };

  const handleMessageBlur = () => {
    setIsMessageFocused(false);
  };
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="form" id="form">
      <img className="form__union" alt="star" src={union} />
      <div className="form__title-container">
        <div className="form__link" to="#">
          <img className="form__limk-img" alt="google-link" src={googleImg} />
        </div>
        <div className="form__title-aria">
          <h2 className="form__title">
            Отримати знижку 1000 грн та Обговорити Ваш Сайт
          </h2>
          <img className="form__title-img" alt="volna" src={volna} />
          <h4 className="form__subtitle">Давайте поговоримо!</h4>
          <div className="form__container">
            <form
              className="form__aria"
              action="https://formspree.io/f/xzbnyywj"
              method="POST"
            >
              <div className="form-group">
                <input
                  type="text"
                  id="inputField"
                  className={`form__input ${isNameFocused ? "focused" : ""}`}
                  placeholder={!isNameFocused ? "Як Вас звати? *" : ""}
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {isNameFocused && (
                  <label htmlFor="inputField" className="placeholder">
                    Як Вас звати? *
                  </label>
                )}
              </div>

              {/*<input
                className="form__input"
                type="email"
                name="email"
                placeholder="Ваш E-mail *"
              />*/}
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  id="inputFieldTwo"
                  className={`form__input ${isPhoneFocused ? "focused" : ""}`}
                  placeholder={!isPhoneFocused ? "Номер телефону *" : ""}
                  onFocus={handlePhoneFocus}
                  onBlur={handlePhoneBlur}
                  onInput={handlePhoneInput}
                  value={phone}
                  required
                />
                {isPhoneFocused && (
                  <label htmlFor="inputFieldTwo" className="placeholder">
                    Номер телефону *
                  </label>
                )}
              </div>

              {/*<input
                className="form__input"
                type="text"
                name="company"
                placeholder="Компанія"
              />*/}
              <div className="form-group">
                <textarea
                  className={`form__input ${isMessageFocused ? "focused" : ""}`}
                  id="form_text"
                  name="message"
                  placeholder={!isMessageFocused ? "Розкажіть про проект" : ""}
                  onFocus={handleMessageFocus}
                  onBlur={handleMessageBlur}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {isMessageFocused && (
                  <label htmlFor="form__input" className="placeholder">
                    Розкажіть про проект
                  </label>
                )}
              </div>

              <label className="form__label">
                {" "}
                Натискаючи кнопку “Відправити”, ви даєте згоду на обробку
                особистих даних.
              </label>
              <NavLink
                className="form__link-two"
                to="/privacy-policy"
                onClick={handleGoToTop}
              >
                Детальніше{" "}
              </NavLink>

              <button className="form__btn" type="submit">
                Обговорити проєкт{" "}
              </button>
            </form>
            <img className="form__elipse" alt="elipse" src={elipse} />

            <div className="contact">
              <div className="contact__container">
                <div className="contact__info">
                  <a
                    className="contact__phone"
                    href="https://t.me/MashaIvolgina"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +380 91 481 81 20
                  </a>
                  <span className="contact__span">Telegram</span>

                  <a
                    className="contact__link"
                    href="mailto:support@brodraw.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@brodraw.com
                  </a>

                  <span className="contact__span">Пишіть, домовимося!</span>
                </div>
              </div>
              <div className="promocode">
                <p className="promocode__info">
                  Знижка 1000 грн з промокодом BRO{" "}
                </p>
                <span className="promocode__smile">😎</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
