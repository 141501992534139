import React from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Link } from "react-scroll";

import "./Navigation.css";

function Navigation() {
  const location = useLocation();
  const { pathname } = location;
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  return (
    <nav className="navigation">
      {pathname === "/" ? (
      <>
        <Link
          className="navigation__link"
          to="promo"
          smooth={true}
          duration={700}
        >
          ВАРТІСТЬ
        </Link>

        <Link
          className="navigation__link"
          to="portfolio"
          smooth={true}
          duration={700}
        >
          ПРОЕКТИ
        </Link>
        <Link
          className="navigation__link"
          to="team"
          smooth={true}
          duration={700}
        >
          КОМАНДА
        </Link>
        <Link
          className="navigation__link"
          to="review"
          smooth={true}
          duration={700}
        >
          ВIДГУКИ
        </Link>
        <Link
          className="navigation__link"
          to="network"
          smooth={true}
          duration={800}
        >
          КОНТАКТИ
        </Link>
      </>
      ) : (<NavLink className="navigation__link" to="/" onClick={handleGoToTop}>НА ГОЛОВНУ
      </NavLink>)}
    </nav>
  );
}

export default Navigation;
