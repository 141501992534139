import React, { useState } from "react";
import "./Cards.css";

function Cards({ onOpenPopup }) {
  const [hoveredStates, setHoveredStates] = useState(Array(4).fill(false));

  const handleMouseEnter = (index) => {
    setHoveredStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <section className="card" id="cards">
      <ul className="card__table">
        {[
          { title: "Landing page", price: 200 + "$" },
          { title: "Інтернет магазин", price: 800 + "$" },
          { title: "Сайт візитка", price: 300 + "$" },
          { title: "Сайт компанії", price: 500 + "$" },
        ].map((card, index) => (
          <li
            key={index}
            className="card__item"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <h4 className="card__title">{card.title}</h4>
            <hr></hr>
            <div className="card__prise">
              <p className="card__par">ціна від</p>
              <p className="card__value">{card.price}</p>
              <button className="card__btn" type="button" onClick={onOpenPopup}>
                Замовити
              </button>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Cards;
