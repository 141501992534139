import React, { useState } from "react";
import "./Network.css";
import volna from "../../images/volna.png";
import btnImageNormal from "../../images/Iconex/Filled/Logout.svg";
import btnImageHover from "../../images/Logoutactive.svg";
import elipse from "../../images/Group12.svg";

function Network(props) {
  const [isEmailHovered, setEmailHovered] = useState(false);
  const [isTelegramHovered, setTelegramHovered] = useState(false);
  const handleEmailMouseEnter = () => {
    setEmailHovered(true);
  };

  const handleEmailMouseLeave = () => {
    setEmailHovered(false);
  };

  const handleTelegramMouseEnter = () => {
    setTelegramHovered(true);
  };

  const handleTelegramMouseLeave = () => {
    setTelegramHovered(false);
  };

  return (
    <section className="network" id="network">
      <h2 className="network__title">
        Є запитання? На все відповімо, маякуйте, коли готові
      </h2>
      <img className="network__title-img" alt="volna" src={volna} />

      <div className="network__container">
        <div className="network__link-container">
          <div
            className="network__link"
            onMouseEnter={handleEmailMouseEnter}
            onMouseLeave={handleEmailMouseLeave}
          >
            <p className="network__link-name">E-mail</p>
            <img
              className="network__link-img"
              alt="door"
              src={isEmailHovered ? btnImageHover : btnImageNormal}
            />
          </div>
          {/*<div className="network__link">
            <p className="network__link-name">Instagram</p>
            <img className="network__link-img" alt="door" src={door} />
          </div>*/}
          {/*<a className="network__link">
            <p className="network__link-name">LinkedIn</p>
            <img className="network__link-img" alt="door" src={door} />
        </a>*/}
          <a
            className="network__link"
            href="https://t.me/MashaIvolgina"
            target="_blank"
            onMouseEnter={handleTelegramMouseEnter}
            onMouseLeave={handleTelegramMouseLeave}
            rel="noopener noreferrer"
          >
            <p className="network__link-name">Telegram</p>
            <img
              className="network__link-img"
              alt="door"
              src={isTelegramHovered ? btnImageHover : btnImageNormal}
            />
          </a>
        </div>

        <img className="network__elipse" alt="elipse" src={elipse} />
      </div>
    </section>
  );
}

export default Network;
