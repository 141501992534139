import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import "./Burger.css";
import logo from "../images/logo375.svg";

function Burger(props) {
  const burgerClass = `burger ${props.isOpen ? "burger_opened" : ""}`;
  const hendleClick = () => {
    props.onClose();
    props.onOpenPopup();
  };
  const location = useLocation();
  const { pathname } = location;
  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <section className={burgerClass}>
      <div className="burger__container">
        <img className="burger__logo" alt="logo" src={logo} />
        <button className="burger__btn" onClick={hendleClick}>
          Зворотній зв'язок
        </button>
        <button className="burger__close" onClick={props.onClose} />
      </div>

      <nav className="nav">
      {pathname === "/" ? (
      <>
      <Link
          className="nav__link"
          to="promo"
          smooth={true}
          duration={700}
          onClick={props.onClose}
        >
          ПРО НАС
        </Link>
        <Link
          className="nav__link"
          to="portfolio"
          smooth={true}
          duration={700}
          onClick={props.onClose}
        >
          ПРОЕКТИ
        </Link>
        <Link
          className="nav__link"
          to="team"
          smooth={true}
          duration={700}
          onClick={props.onClose}
        >
          КОМАНДА
        </Link>
        <Link
          className="nav__link"
          to="review"
          smooth={true}
          duration={700}
          onClick={props.onClose}
        >
          ВIДГУКИ
        </Link>
        <Link
          className="nav__link"
          to="network"
          smooth={true}
          duration={800}
          onClick={props.onClose}
        >
          КОНТАКТИ
        </Link></>) : (<NavLink className="navigation__link" to="/" onClick={handleGoToTop && props.onClose}>На главную </NavLink>)}
      </nav>
      <div className="contact" id="burger__conatct">
        <div className="contact__container" id="burger_contact-container">
          <div className="contact__info" id="burger_info">
            <a
              className="contact__phone"
              id="burger_phone"
              href="https://t.me/MashaIvolgina"
              target="_blank"
              rel="noopener noreferrer"
            >
              099 241 81 31
            </a>
            <span className="contact__span">Telegram</span>

            <a
              className="contact__link"
              href="https://t.me/MashaIvolgina"
              target="_blank"
              id="burger_contact-link"
              rel="noopener noreferrer"
            >
              @Написати
            </a>

            <span className="contact__span">Пишіть, домовимося!</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Burger;
