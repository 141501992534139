import React, { useState } from "react";
import "./Review.css";
import star from "../../images/Star.png";
import volna from "../../images/volna.png";
import googleImg from "../../images/link-google.png";
import quotes from "../../images/,,.svg";


function Review({ feedbacks }) {

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % feedbacks.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? feedbacks.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="review" id="review">
      <div className="review__container">
        <div className="review__container-title">
          <h3 className="review__title">ВІДГУКИ ВіД НАШИХ КЛІЄНТІВ</h3>
          <div className="review__star">
            <img className="review__img-star" alt="star" src={star} />
            <img className="review__img-star" alt="star" src={star} />
          </div>
        </div>
        <img className="review__title-img" alt="volna" src={volna} />
        <div className="review__button-container">
          <button className="review__button-back" onClick={handlePrev} />
          <button className="review__button-next" onClick={handleNext} />
        </div>
        <div className="review__text">
          <img className="review__quotes" alt="quotes" src={quotes} />

          <div className="review__par-container">
            <img className="review__google" alt="google-link" src={googleImg} />
            <div className="review__second-container">
              <p className="review__par">{feedbacks[activeIndex].text}</p>
              <span className="review__owner">
                {feedbacks[activeIndex].author}
              </span>
            </div>
          </div>

          <div className="review__dot">
            {feedbacks.map((_, index) => (
              <button
                key={index}
                className={`review__button-dot ${
                  index === activeIndex ? "review__button-dot--active" : ""
                }`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
