import React,{useState, useEffect} from "react";
import "./Header.css";
import Navigation from "../Navigation/Navigation";
//import lenguage from "../images/language.svg";
import btnImageNormal from ".././images/Iconex/Filled/Logout.svg";
import btnImageHover from ".././images/Logoutactive.svg";
import { useLocation} from "react-router-dom";
import { NavLink } from "react-router-dom";

function Header(props) {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);

  //const [showNavTab, setShowNavTab] = useState(false);
  const btnImage = props.isHovered ? btnImageHover : btnImageNormal;
  const { pathname } = location;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (pathname === "/privacy-policy"){
    return(<header className="header">
    <div className="header__container">
      <NavLink className="header__logo" to ="/"/>
        <Navigation />
        <div className="header__second-container">
        </div>
</div>
  </header>)
  }

        else if (!isMobile){
          return (
          <header className="header">
      <div className="header__container">
        <NavLink className="header__logo" to ="/"/>
          <Navigation />
          <div className="header__second-container">
            {/*<img className="header__lenguage" alt="lenguage" src={lenguage} />*/}
            <button
              className="header__btn"
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onOpenPopup}
            >
              Зв’затися з нами
              <img className="header__btn-img" alt="logout" src={btnImage} />
            </button>
          </div>
  </div>
    </header>
  );
}
else{
  return (
    <header className="header">
<div className="header__container">
<NavLink className="header__logo" to ="/"/>
  <button className="header__menu" onClick={props.onOpenBurger}></button>
    </div>

</header>
);

}}

export default Header;
