import React from "react";
import Promo from "./Promo/Promo";
import Cards from "./Cards/Cards";
import Review from "./Review/Review";
import Promotion from "./Promotion/Promotion";
import Portfolio from "./Portfolio/Portfolio";
import Form from "./Form/Form";
import Team from "./Team/Team";
import Network from "./Network/Network";
import { employees, feedbacks } from "../utils/constants";

function Main(props) {
  return (
    <main className="main">
      <Promo onOpenPopup={props.onOpenPopup} />
      <Cards onOpenPopup={props.onOpenPopup} />
      <Review feedbacks={feedbacks} />
      <Promotion
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        isHovered={props.isHovered}
      />
      <Portfolio />
      <Form />
      <Team employees={employees} />
      <Network
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        isHovered={props.isHovered}
      />
    </main>
  );
}

export default Main;
