import React from "react";
import "./Footer.css";
import Navigation from "../Navigation/Navigation";
import logo from "../images/logo.svg";
// made be Kelendis2
function Footer(props) {
  return (
    <footer className="footer">
      <div className="footer__container">
        <img className="footer__logo" alt="logo" src={logo} />
        <Navigation />
        <div className="footer__container-two">
          <button className="footer__btn" onClick={props.onOpenPopup}>
          Зворотній зв'язок
          </button>
        </div>
      </div>
      <div className="footer__info">
      <p className="footer__par">© Copyright 2024 BRODRAW. All Rights reserved.</p>
      <p className="footer__par">Політика конфіденційності / Договир оферта</p>
      <p className="footer__par">Development by BroDraw</p>

      </div>

    </footer>
  );
}

export default Footer;

