import React from "react";
import "./Promo.css";
import volna from "../../images/volna.png";
import star from "../../images/Star.png";
import googleImg from "../../images/link-google.png";
import smile from "../../images/txt.png";


function Promo(props) {
  return (
    <>
      <section className="promo" id="promo">
        <div className="promo__container">
          <div className="promo__links">
            <img className="promo__img-star" alt="star" src={star} />
              <img
                className="promo__limk-img"
                alt="google-link"
                src={googleImg}
              />
          </div>
          <img className="promo__title-img" alt="volna" src={volna} />
          <div className="promo__text">
            <h1 className="promo__title">
              Ми Агенція BroDraw, cтворюємо кайфофі BeБ-Сайти{" "}
            </h1>
            <h3 className="promo__subtitle">
              Давай нарешті запустимо твій ульотний вебсайт{" "}
            </h3>
            {/*<img className="promo__ellipse" alt="Ellipse" src={ellipse} />*/}
            <div className="promo__btns">
            <button className="promo__btn" onClick={props.onOpenPopup}>Зв’язатися з нами</button>
            <div className="promo__code">
              <p className="promo__par">
                Знижка 1000 грн з промокодом BRO
              </p>
              <img className="promo__img" alt="smile" src={smile} />
            </div>
          </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Promo;
