import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Main from "../Main/Main";
import NotFound from "../NotFound/NotFound";
import Popup from "../Popup/Popup";
import Burger from "../Burger/Burger";
import Network from "../Main/Network/Network";
import Privacy from "../Privacy/Privacy";


function App() {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpenPopup, setPopupOpen] = useState(false);

  const [isOpenBurger, setOpenBurger] = useState(false);

  const hendleOpenBurger = () => {
    setOpenBurger(true);
  };
  const hendleCloseBurger = () => {
    setOpenBurger(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hendleOpenPopup = () => {
    setPopupOpen(true);
  };




  const hendleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="app">
      <Header
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isHovered={isHovered}
        onOpenPopup={hendleOpenPopup}
        onOpenBurger={hendleOpenBurger}
      />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/"
          element={
            <>
              <Main
                onOpenPopup={hendleOpenPopup}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                isHovered={isHovered}
              />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <Privacy />
              <Network />
            </>
          }
        />
      </Routes>
      <Footer onOpenPopup={hendleOpenPopup} />

      <Popup isOpen={isOpenPopup} onClose={hendleClosePopup} />

      <Burger
        isOpen={isOpenBurger}
        onClose={hendleCloseBurger}
        onOpenPopup={hendleOpenPopup}
      />
    </div>
  );
}

export default App;
