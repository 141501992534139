import React, { useState } from "react";
import "./Popup.css";

function Popup({ isOpen, onClose }) {
  const [namePopup, setNamePopup] = useState("");
  const [phonePopup, setPhonePopup] = useState("");
  const [messagePopup, setMessagePopup] = useState("");
  const handlePhoneInput = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    setPhonePopup(input);
  };

  const popupClass = `popup ${isOpen ? "popup_opened" : ""}`;

  return (
    <section className={popupClass}>
      <div className="popup__container">
        <button className="popup__close" onClick={onClose}></button>

        <h2 className="popup__title">Замовити дзвінок </h2>
        <form
          className="popup__form"
          action="https://formspree.io/f/mbjnlgnk"
          method="POST"
        >
          <input
            className="popup__input"
            type="text"
            name="name"
            placeholder="Як Ваше ім’я*"
            value={namePopup}
            onChange={(e) => setNamePopup(e.target.value)}
            required
          />
          <input
            className="popup__input"
            type="text"
            name="phone"
            placeholder="Ваш номер телефону*"
            onInput={handlePhoneInput}
            value={phonePopup}
            required
          />
          <textarea
            className="popup__input"
            id="popup__text"
            name="message"
            placeholder="Ваш коментарій"
            value={messagePopup}
            onChange={(e) => setMessagePopup(e.target.value)}
          ></textarea>
          <button className="popup__btn" type="submit">
            Обговорити проєкт
          </button>
        </form>
      </div>
    </section>
  );
}

export default Popup;
