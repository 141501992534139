import React from "react";
import "./Portfolio.css";
import siteFirst from "../../images/Marey1.svg";
import siteSecond from "../../images/fhoto.svg";
import siteThird from "../../images/InterCOM.svg";
import siteFourth from "../../images/BioHumus.svg";
import elipse from "../../images/elipse_portfolio.svg";

function Portfolio() {
  return (
    <section className="portfolio">
      <ul className="portfolio__table">
        <li className="portfolio__card" id="first_card">
          <div className="portfolio__card-container" id="first-container">
            <h3 className="portfolio__card-title">450 + </h3>
            <p className="portfolio__card-par">реалізованийх проектів в ІТ</p>
          </div>
        </li>

        <li className="portfolio__card">
          <img className="portfolio__card-image" alt="site" src={siteSecond} />
          <div className="portfolio__card-container">
            <h3 className="portfolio__card-name">Ecommerce</h3>
            <div className="portfolio__card-info">
              <a
                className="portfolio__card-subtitle"
                href="https://ironbox.com.ua"
                target="_blank"
                rel="noopener noreferrer"
              >
                Інтернет магазин: тюнінг та деталі для автоспорту
              </a>
              <a
                className="portfolio__card-arrow"
                href="https://ironbox.com.ua"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
              </a>

              <p className="portfolio__card-description" id="description-one">
                Магазин що привертає увагу закоханих в автоспорт зі зручним
                пошуком деталей
              </p>
            </div>
          </div>
        </li>

        <li className="portfolio__card">
          <img className="portfolio__card-image" alt="site" src={siteFirst} />
          <div className="portfolio__card-container">
            <h3 className="portfolio__card-name">Ecommerce</h3>
            <div className="portfolio__card-info">
              <a
                className="portfolio__card-subtitle"
                href="https://mareyshop.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                Інтернет магазин: виробництво продукції для лешмейкерів
              </a>
              <a
                className="portfolio__card-arrow"
                href="https://mareyshop.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
              </a>
              <p className="portfolio__card-description">
                Магазин для продажу матеріалів та курсів з урахуванням вимог
                європейського ринку
              </p>
            </div>
          </div>
        </li>

        <li className="portfolio__card" id="card_five">
          <img className="portfolio__card-image" alt="site" src={siteFourth} />
          <div className="portfolio__card-container" id="container_five">
            <h3 className="portfolio__card-name">Ecommerce</h3>
            <div className="portfolio__card-info">
              <a className="portfolio__card-subtitle" href="https://em-viton.com"
                target="_blank"
                rel="noopener noreferrer">
                Інтернет магазин: біо гумус для всіх типів рослин
              </a>
              <a
                className="portfolio__card-arrow"
                href="https://em-viton.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
              </a>
              <p className="portfolio__card-description" id="description-three">
                Біо гумус який хочеться придбати бо показали результати та
                переваги його використання
              </p>
            </div>
          </div>
        </li>

        <li className="portfolio__card" id="card__autoschool">
          <img className="portfolio__card-image" alt="site" src={siteThird} />
          <div className="portfolio__card-container">
            <h3 className="portfolio__card-name">Ecommerce</h3>
            <div className="portfolio__card-info">
              <a className="portfolio__card-subtitle" href="https://intercom.sumy.ua/"
                target="_blank"
                rel="noopener noreferrer">
                Сайт компанії: автошкола для всіх рівнів водіїв
              </a>
              <a
                className="portfolio__card-arrow"
                href="https://intercom.sumy.ua/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
              </a>
              <p className="portfolio__card-description" id="autoschool">
                Автошкола яка викликає довіру і має зрозуміле ціноутворення та
                спеціальні пропозиції
              </p>
            </div>
          </div>
        </li>

        <li className="portfolio__card" id="last_card">
          <img className="portfolio__card-elipse" alt="elipse" src={elipse} />
        </li>
      </ul>
      {/*
      <button className="portfolio__btn">
        Завантажити ще
        <img className="portfolio__btn-img" alt="plus" src={plus}></img>
      </button>
      */}
    </section>
  );
}

export default Portfolio;
