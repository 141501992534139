import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <section className="privacy">
      <h1 className="privacy__title">політика конфіденційності</h1>
      <span className="privacy__span">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ BroDraw </span>
      <div className="privacy__container">
        <h2 className="privacy__subtitle">І. Загальні положення</h2>
        <p className="privacy__par">
          Конфіденційність Вашої інформації є одним із пріоритетів нашої
          діяльності, відтак ми докладаємо всіх можливих зусиль для забезпечення
          її захисту. Дана Політика конфіденційності (надалі – «Політика»)
          вступила в силу з 29 листопада 2021 року. Останні оновлення
          опубліковано 28 грудня 2022 року.
        </p>
        <p className="privacy__par">
          Мета Політики – описати, яким чином Інститут BroDraw, його дочірні
          компанії, міжнародні філії, партнери, підрядники та уповноважені особи
          («Projector», «ми», «наш», «нами» тощо) збирає, зберігає, використовує
          та обмінюється інформацією про Вас (далі також «користувач») через
          наші Інтернет-інтерфейси (наприклад, веб-сайти та мобільні додатки),
          що належать і контролюються нами, включаючи, але не обмежуючись даним
          сайтом https://prjctr.com/, а також сторінками в соціальних мережах,
          чатах тощо (надалі спільно іменовані «Сайт»), та які права Ви маєте у
          відношенні до зібраної нами інформації.
        </p>
        <p className="privacy__par">
          Будь-ласка, уважно прочитайте Публічні оферти перед використанням
          нашого Сайту та послуг. Приймаючи умови Публічної оферти, Ви надаєте
          згоду на збирання, зберігання та розголошення Вашої інформації, як
          описано в Політиці, а також на надсилання Вам певних повідомлень або
          передачу нам певної інформації.
        </p>
        <p className="privacy__par">
          У випадку, якщо Ви не погоджуєтесь з будь-яким положенням Політики, Ви
          зобов'язані припинити використання сервісів нашого Сайту.
        </p>
        <p className="privacy__par">
          Даючи свою згоду на ті види обробки персональних даних, де юридичною
          підставою є згода, Ви підтверджуєте, що маєте достатню правоздатність
          та дієздатність за законами Вашого місцеперебування для надання такої
          згоди. У випадку, якщо Ви не маєте достатнього обсягу дієздатності,
          згода повинна бути надана Вашим законним представником (одним з
          батьків/опікуном), що мають повний обсяг цивільної дієздатності та
          уповноважені надавати таку згоду.
        </p>
        <p className="privacy__par">
          BroDraw залишає за собою право в односторонньому порядку доповнювати,
          змінювати та/або розширювати Політику, тому ми рекомендуємо періодично
          перевіряти наявність змін та/або оновлень до Політики на Сайті.
        </p>
        <p className="privacy__par">
          Продовжуючи користуватися Сайтом після опублікування змін, Ви
          погоджуєтесь з новою редакцією Політики. Щоб дізнатись, коли ми
          востаннє оновлювали цю Політику, зверніться до дати, зазначеної після
          «Останні оновлення» у верхній частині цієї Політики. Якщо Ви не згодні
          зі змінами, Вам слід негайно припинити використання Сайту.
        </p>
        <p className="privacy__par">
          Якщо у Вас виникають будь-які запитання та/або претензії відносно
          Політики, просимо звернутись до служби підтримки користувачів Сайту
          bro@draw.com.ua
        </p>
        <p className="privacy__par">
          Якщо Ви заперечуєте щодо використання Ваших персональних даних для
          звернень до Вас у майбутньому з будь-якою метою, просимо звернутись до
          служби підтримки користувачів Сайту і ми врахуємо Ваші побажання.
        </p>
        <p className="privacy__par">
          Якщо Ви проживаєте або перебуваєте в Європейському економічному
          просторі («EEA»), BroDraw є оператором (володільцем персональних
          даних) усієї інформації, що ідентифікує особу (як визначено нижче),
          зібраної на Сайті, та певної інформації, що ідентифікує особу,
          зібраної від третіх сторін, як зазначено в Політиці.
        </p>
        <p className="privacy__par">
          Політика застосовується до інформації, зібраної BroDraw через Сайт,
          або будь-яким іншим способом, як описано нижче. Положення Політики не
          поширюються на діяльність будь-яких сторонніх сервісів/служб та
          веб-сайтів, додатків та/або послуг, якими BroDraw не володіє та не
          підтримує (Послуги третіх осіб). Як детально описано нижче, BroDraw не
          несе відповідальності за політику конфіденційності будь-яких третіх
          осіб.
        </p>
        <h2 className="privacy__subtitle">ІІ. Яку інформацію ми збираємо</h2>
        <span className="privacy__par-span">
          Інформація про користувачів Сайту (інформаційні дані)
        </span>
        <p className="privacy__par">
          Наприклад, ім'я, прізвище та по-батькові, адреса електронної пошти,
          номер мобільного (контактного телефону).
        </p>
        <p className="privacy__par">
          Залежно від особливостей та умов проведення окремих освітніх та/або
          інших заходів, проектів, може бути визначено потребу в отриманні
          додаткових даних (вік, заклад навчання, сфера професійної діяльності
          тощо), про що буде зазначено у відповідному розділі Сайту.
        </p>
        <p className="privacy__par">
          Деякі обмежені функціональні можливості нашого Сайту можна
          використовувати, не розкриваючи жодної інформації, що ідентифікує
          особу. Для споживання пропонованих Сайтом послуг та можливостей,
          потрібна інформація, що ідентифікує особу.
        </p>
        <p className="privacy__par">
          Для того, щоб отримати доступ до певних функцій, послуг, пропозицій
          та/або переваг на нашому Сайті (разом «Послуги»), Вам потрібно надати,
          або ми можемо запросити «Інформацію, що ідентифікує особу», яка може
          бути використана для Вашої ідентифікації як користувача (може також
          називатися «Персональними даними» або «Персональною інформацією»).
        </p>
        <p className="privacy__par">
          Ми збираємо Персональну інформацію, яку Ви надаєте нам, коли
          реєструєтесь для облікового запису або для отримання певної Послуги на
          Сайті, оновлюєте або змінюєте інформацію для свого облікового запису,
          замовляєте Послуги, заповнюєте опитування, реєструєтесь для оновлення
          електронною поштою, берете участь у наших публічних форумах,
          надсилаєте нам електронні листи, повідомлення та/або берете участь у
          пропозиціях та/або інших сервісах на Сайті. 
        </p>
        <p className="privacy__par">
          Ми можемо використовувати Персональну інформацію, яку Ви надаєте, щоб
          відповісти на Ваші запитання, надати Вам конкретну Послугу, яку Ви
          обрали, надіслати Вам пропозиції по Послугах, які можуть Вас
          зацікавити, надіслати Вам оновлення по Послугах BroDraw та/або
          повідомлення електронною поштою про обслуговування або оновлення
          Сайту. 
        </p>
        <span className="privacy__par-span">
          Інформація про листування (комунікацію) з користувачем (дані
          комунікації)
        </span>
        <p className="privacy__par">
          Дана категорія даних включає листи, коментарі, пропозиції, скарги,
          включно з їх змістом (який може включати, серед іншого, фото, відео,
          аудіо тощо), надіслані Вами за допомогою чатів, електронної пошти,
          соціальних мереж, телефону та/або будь-яку іншу інформацію про
          листування між нами та Користувачем онлайн, у вигляді електронного
          та/або звичайного листа та/або особистою кореспонденції між Вами та
          BroDraw.
        </p>
        <p className="privacy__par">
          Будь ласка врахуйте, що якщо Ви зв'язуєтесь з нами за допомогою
          телефону, ми зберігаємо дані телефонної розмови, які включають: номер
          телефону, з якого Ви телефонуєте, а також запис розмови.
        </p>
        <span className="privacy__par-span">
          Інформація щодо участі користувача в різних заходах та/або проектах
          BroDraw
        </span>
        <p className="privacy__par">
          Ми збираємо персональні дані, що вказані Вами у заявках на участь
          та/або формах реєстрації, а також історію Вашої участі в діяльності
          BroDraw, в тому числі – заходах/ініціативах/проектах тощо.
        </p>
        <span className="privacy__par-span">
          Інформація про пристрої та технічні дані (дані, що збираються
          автоматично)
        </span>
        <p className="privacy__par">
          Такі дані включають ІР-адресу користувача, дані про операційну систему
          комп'ютера/мобільного пристрою та тип браузера, тип мобільного
          пристрою, тип підключення до мережі (наприклад, Bluetooth, WiFi, 3G,
          4G, LTE тощо), мовні налаштування у Вашому мобільному пристрої,
          оператора мобільного зв'язку, унікальний ідентифікатор пристрою чи
          ідентифікатор мобільного обладнання користувача тощо, які обробляються
          автоматично (в тому числі при завантаженні сторінок Сайту).
        </p>
        <p className="privacy__par">
          Ми також використовуємо (або можемо використовувати) файли cookie або
          схожі технології відстеження, коли Ви відвідуєте наш Веб-сайт. Файли
          cookie зазвичай не містять жодної інформації, яка ідентифікує особу
          користувача. Проте, Ваша персональна інформація, яку ми маємо, може
          бути пов'язана з інформацією, що зберігається та отримана від файлів
          cookie.
        </p>
        <p className="privacy__par">
          Щоб отримати додаткову інформацію про використання файлів cookie,
          зверніться до Політики використання файлів cookie.
        </p>
        <p className="privacy__par">
          Ці дані збираються як самим Сайтом, так і за допомогою сторонніх
          інструментів Аналітики (включаючи, але не обмежуючись, Google
          Analytics, Cloudflare тощо).
        </p>
        <span className="privacy__par-span">
          Інформація про відвідування Вами Сайту (дані користування Сайтом)
        </span>
        <p className="privacy__par">
          Коли Ви заходите на Сайт, ми можемо відстежувати, збирати та
          узагальнювати інформацію, вказуючи, серед іншого, які сторінки Сайту
          були Вами відвідані, порядок їх відвідування, коли вони були відвідані
          та на які гіперпосилання Ви заходили, помилки із завантаженням
          сторінок Сайту, тривалість відвідування певних сторінок Сайту, а також
          інформацію про взаємодію між сторінками Сайту, яка обробляється
          автоматично (в тому числі при завантаженні). 
        </p>
        <p className="privacy__par">
          Ми збираємо інформацію за URL-адресами, з яких Ви зробили посилання на
          наш Сайт. 
        </p>
        <span className="privacy__par-span">Оглядова інформація</span>
        <p className="privacy__par">
          Ми збираємо відгуки та/або відповіді користувача на запропоновані нами
          маркетингові, рекламні та/або інформаційні опитування/дослідження.
        </p>
        <span className="privacy__par-span">Інформація про обговорення</span>
        <p className="privacy__par">
          Ми можемо запропонувати користувачу доступ до публічно доступних
          чатів, форумів та/або обговорень на Сайті (у створених чатах, форумах
          тощо), де користувачі можуть розміщувати власні коментарі та/або
          обговорення щодо діяльності BroDraw, в тому числі організації та
          проведення заходів/проектів тощо. Ці коментарі та обговорення можуть
          містити інформацію, яка підпадає під визначення «персональні дані»
          відповідно до чинного законодавства.
        </p>
        <p className="privacy__par">
          При цьому, будь ласка врахуйте, що використовуючи публічно доступні
          чати, форуми та обговорення, Ви підтверджуєте своє повне розуміння та
          усвідомлення, що вся опублікована Вами інформація буде знаходитись в
          публічному доступі в мережі Інтернет, та буде доступною для всього
          світу.
        </p>
        <p className="privacy__par">
          Якщо Ви берете участь в такому публічно доступному чаті, форумі та/або
          обговоренні, де персональні дані користувачів можуть бути розміщені у
          мережі Інтернет, окрім ознайомлення з цією Політикою, рекомендуємо
          також обов'язково перевірити правила публічно доступного чату, форуму
          та/або обговорення перед введенням Вами даних.
        </p>
        <span className="privacy__par-span">
          Інформація, отримана від третіх сторін (дані від третіх сторін)
        </span>
        <p className="privacy__par">
          У деяких випадках, ми збираємо додаткові персональні дані користувача
          з інших джерел, у тому числі від компаній-партнерів, компаній у сфері
          технічного обслуговування постачальників інформаційного пошуку, з баз
          даних для боротьби з шахрайством та/або інших сторонніх баз даних, у
          тому числі з санкційних (заборонних) списків у тих випадках, коли це
          передбачено законом. Такі дані можуть включати, без обмежень,
          інформацію, яка використовується для перевірки достовірності
          інформації про користувача; та інформацію про дієздатність
          користувача.
        </p>
        <span className="privacy__par-span">
          Персональна інформація, зокрема, але не виключно, може збиратись з
          таких джерел:
        </span>
        <p className="privacy__par">
          • Реєстрація на Сайті. Якщо Ви реєструєтеся на Сайті, Вам потрібно
          надати нам особисту інформацію (як-то, наприклад: ім'я користувача,
          зображення у профілі, телефонний номер, адреса електронної пошти
          тощо).
          <br />
          •Оновлення. Ми можемо запропонувати Вам можливість отримувати
          оновлення електронною поштою або розміщуючи повідомлення на сторінках
          Сайту, доступних лише для зареєстрованих користувачів. Для того, щоб
          підписатися на ці Послуги, Вам може бути потрібно надати нам особисту
          інформацію, таку як Ваше ім'я та електронна адреса. <br />
          •Участь у пропозиціях Сайту. Ми пропонуємо користувачам можливість
          брати участь у різних пропозиціях на Сайті або через нього. Якщо Ви
          бажаєте взяти участь у відповідній пропозиції, Вас попросять надати
          нам певну інформацію, необхідну для проведення такої пропозиції. Ця
          інформація може включати, серед іншого, Ваше ім'я та електронну
          адресу, завдання, які Ви подаєте викладачам, відгуки студентів тощо.
          Ми також збираємо дані курсу, такі як відповіді студентів на тести,
          іспити та опитування. Ви не повинні надавати будь-яку особисту
          інформацію або іншу інформацію особистого чи конфіденційного
          характеру, незалежно від того, чи стосується вона Вас чи іншої особи,
          на завданнях, іспитах чи опитуваннях, за винятком інформації,
          необхідної для участі або подання таких завдань, іспитів чи опитувань.{" "}
          <br />
          •Перевірка особи. Ми можемо запропонувати Вам можливість підтвердити
          свою особу для деяких сервісів Сайту. Для цього Вам може знадобитися
          надати нам або нашому сторонньому постачальнику особисту інформацію,
          таку як Ваше ім'я, телефон, електронна адреса тощо. <br />
          •Зв'язок з нами. Ми можемо отримати інформацію, що ідентифікує особу,
          коли Ви надсилаєте нам повідомлення електронною поштою або іншим чином
          зв'язуєтесь з нами, в тому числі коли Ви будь-яким чином комунікуєте з
          будь-яким підрозділом BroDraw (наприклад, службою технічної
          підтримки). <br />
          •Сторонні сайти. Ми можемо отримувати інформацію, що ідентифікує
          особу, коли Ви реєструєтесь або входите (логінитесь) на веб-сайті
          третьої сторони, наприклад, Facebook, із нашого Сайту. Це може
          включати текст та/або зображення Вашої Персональної інформації,
          доступної на веб-сайті третьої сторони.
          <br />
          •Опитування. Ми можемо отримати Персональну інформацію, коли Ви
          надаєте інформацію у відповідь на опитування, проведене нами або
          постачальником контенту для Послуг Сайту. <br />
          •Партнерські сайти. Партнерські сайти, що надають інструменти та
          послуги, які пропонуються для користувачів нашого Сайту, можуть
          збирати Ваші Персональні дані, щодо використання Вами цього
          партнерського веб-сайту. Партнерські сайти можуть обмінюватися цими
          даними з BroDraw з метою вдосконалення Послуг BroDraw та послуг
          веб-сайту партнерів. Ці дані включають, зокрема, але не виключно, таку
          інформацію, як кількість часу, проведеного на веб-сайті партнера та
          переглянуті сторінки. <br />
          •Обробка даних кредитних карток. BroDraw надає Вам можливість
          оплачувати Послуги за допомогою кредитної картки через стороннього
          постачальника послуг з обробки платежів. Зверніть увагу, що
          постачальник послуг з обробки платежів, а не BroDraw, збирає та
          обробляє інформацію про Вашу кредитну картку.
        </p>
        <p className="privacy__par">
          Ви несете повну відповідальність за забезпечення достовірності всієї
          Персональної інформації, яку Ви надаєте BroDraw. Неточна інформація
          може вплинути на Вашу здатність користуватися Сайтом і нашу здатність
          зв'язуватися з Вами. Ви зобов'язані оновлювати Вашу Персональну
          інформацію у випадку її зміни; зокрема, своєчасно вносити зміни до
          електронної адреси.
        </p>
        <p className="privacy__par">
          Перед тим, як розкрити для нас персональну інформацію третьої особи,
          Ви повинні отримати згоду цієї особи як на розкриття, так і на обробку
          цієї інформації у відповідності до умов Політики. І ми презюмуємо, що
          така згода Вами отримана.
        </p>
        <h2 className="privacy__subtitle">
          ІІІ. За яких умов ми використовуємо Персональну інформацію
        </h2>
        <p className="privacy__par">
          Персональна інформація, яку Ви передали нам через Сайт, буде
          використовуватися задля цілей, зазначених в Політиці або на
          відповідних сторінках Сайту. 
        </p>
        <span className="privacy__par-span">
          Ми можемо використовувати Вашу персональну інформацію за наступних
          умов:
        </span>
        <p className="privacy__par">
          {" "}
          1. для виконання наших зобов'язань перед Вами (укладення та виконання
          договору), зокрема, для:
        </p>
        <p className="privacy__par">
          {" "}
          •дотримання умов використання Послуг, які Ви приймаєте, переглядаючи
          відповідні сторінки Сайту;
          <br /> •надання Вам Послуг, придбаних через Сайт, та дотримання умов
          надання таких Послуг;  <br />
          •надання Вам можливості користуватися сервісами, доступними на Сайті;
          <br /> •надсилання Вам повідомлень, рахунків та нагадувань про оплату,
          та отримання платежів від Вас; 
        </p>
        <p className="privacy__par">
          2. для дотримання наших юридичних зобов'язань перед третіми особами;
          або
        </p>
        <p className="privacy__par">
          {" "}
          3. для забезпечення наших законних інтересів або законних інтересів
          інших осіб. При цьому, нашими законними інтересами є:
        </p>
        <p className="privacy__par">
          {" "}
          •керувати, розвивати та розширювати сферу діяльності BroDraw;
          <br />
          •управляти Сайтом та надавати Послуги; •вибирати відповідних
          кваліфікованих постачальників; •будувати стосунки з партнерами та
          науковими установами;
          <br /> •проводити дослідження та статистичний аналіз; •проводити
          маркетингові активності; <br />
          •забезпечувати внутрішні адміністративні та аудиторські цілі;
          •забезпечувати безпеку нашого Сайту та попереджувати шахрайство;
          <br /> •перевіряти відповідність будь-якої активності, здійснюваної на
          Сайті, умовам та правилам, які регламентують використання нашого Сайту
          (включаючи моніторинг приватних повідомлень, надісланих через сервіс
          приватних повідомлень нашого Сайту).
        </p>
        <p className="privacy__par">4. в інших цілях.</p>
        <p className="privacy__par">
          Те, яка правова підстава застосовується до конкретної діяльності з
          обробки Вашої Персональної інформації, буде залежати від типу
          оброблюваної Персональної інформації та контексту, в якому вона
          оброблюється.
        </p>
        <p className="privacy__par">
          Якщо ми покладаємось на наші законні інтереси (або законні інтереси
          іншої особи) для використання Вашої Персональної інформації, ми будемо
          проводити аналіз співвідношення пріоритету інтересів, щоб
          переконатись, що наші законні інтереси (або законні інтереси іншої
          особи) не переважають Ваші інтереси чи основні права та свободи, які
          передбачають захист Персональної інформації.
        </p>
        <p className="privacy__par">
          Ми можемо обробляти Вашу Персональну інформацію в деяких випадках для
          маркетингових цілей на основі Вашої згоди (яку Ви можете відкликати в
          будь-який час, як описано нижче).
        </p>
        <p className="privacy__par">
          Якщо ми покладаємось на Вашу згоду на використання Вашої Персональної
          інформації певним чином, але пізніше Ви зміните своє рішення, Ви
          можете в будь-який час відкликати свою згоду, відвідавши сторінку
          свого профілю та клацнувши поле для видалення згоди чи видалення
          Вашого облікового запису, або повідомивши службу підтримки, і ми
          припинимо використання Вашої Персональної інформації. При цьому,
          будь-ласка майте на увазі, що відкликання Вами згоди на використання
          Вашої Персональної інформації може вплинути на нашу можливість
          надавати Вам Послуги.
        </p>
        <p className="privacy__par">
          Якщо Ви надали персональну інформацію для публікації на нашому Сайті,
          ми опублікуємо її. В іншому випадку, ми використовуватимемо цю
          інформацію у відповідності до ліцензії, яку Ви нам надали.
        </p>
        <h2 className="privacy__subtitle">
          IV. Якими способами ми використовуємо Персональну інформацію
        </h2>
        <p className="privacy__par">
          1. Інформація, що стосується використання Вами нашого Сайту. 
        </p>
        <p className="privacy__par">
          Ми використовуємо інформацію, що стосується Вашого використання Сайту,
          для створення більш якісних, корисних Послуг, проводячи статистичний
          аналіз колективних характеристик та поведінки наших користувачів, а
          також вимірюючи демографічні показники та інтереси щодо конкретних
          сторінок та Послуг Сайту. Ми також можемо використовувати цю
          інформацію для забезпечення безпеки користувачів та Сайту.
        </p>
        <p className="privacy__par">
          За винятком випадків, зазначених у Політиці або окремо узгоджених з
          Вами, BroDraw не буде розголошувати жодну Вашу Персональну інформацію.
        </p>
        <p className="privacy__par">
          Окрім інших видів використання, викладених у Політиці, ми можемо
          розголошувати та використовувати інформацію, що ідентифікує особу, для
          наступного:
          <br /> • щоб надати Вам доступ до Сайту та можливість використовувати
          його сервіси, а також для надання будь-якої інформації, продуктів чи
          послуг, які Ви замовляєте у нас; <br />• щоб надати Вам технічну
          підтримку, де це потрібно, та забезпечити безпеку користувачів, Послуг
          та Сайту; <br />• щоб надсилати Вам повідомлення у зв'язку з
          пропозиціями Сайту;
        </p>
        <p className="privacy__par">
          Ми також можемо ділитися Вашою Персональною інформацією та Вашими
          результатами з викладачем або викладачами, які вели відповідний курс,
          з асистентами викладання або іншими особами, призначеними викладачем
          для сприяння у створенні, зміні або проведенні курсу, а також з
          замовниками (партнерами);
        </p>
        <p className="privacy__par">
          Крім того, ми можемо архівувати цю інформацію та/або використовувати
          її для подальшого спілкування з Вами у випадках, зазначених у Політиці
          або в Публічних офертах;
        </p>
        <p className="privacy__par">
          • для підтвердження Вашої особи та для підтвердження достовірності
          поданих Вами на Сайті матеріалів;
          <br /> • для зв'язку з Вами у відповідь на Ваше повідомлення та/або як
          описано в Політиці;
        </p>
        <p className="privacy__par">
          Якщо ми надсилаємо Вам електронні листи, ми можемо відстежувати спосіб
          взаємодії з цими електронними листами (наприклад, коли Ви відкриваєте
          електронне повідомлення або натискаєте посилання всередині листа). Ми
          використовуємо цю інформацію з метою оптимізації та кращого
          адаптування наших комунікацій до Вас;
        </p>
        <p className="privacy__par">
          • для пропонування Вам послуг наших ділових партнерів;
        </p>
        <p className="privacy__par">
          Ми можемо ділитися Вашою Персональною інформацією з постачальниками
          послуг та іншими діловими партнерами BroDraw, щоб вказані особи могли
          обмінюватися інформацією про свої продукти та послуги, яка може Вас
          зацікавити, якщо вони мають на це законне право;
        </p>
        <p className="privacy__par">
          BroDraw залишає за собою право розкрити Вашу Персональну інформацію
          для будь-якого з партнерів, страхувальників, професійних радників,
          агентів, постачальників або субпідрядників, в обсягу та цілях,
          визначених в Політиці;
        </p>
        <p className="privacy__par">
          • для досліджень, пов'язаних з онлайн-консультуванням та
          онлайн-освітою; <br />• для можливості роботи наших підрядників з
          експлуатації та обслуговування;
        </p>
        <p className="privacy__par">
          Ми використовуємо різних постачальників послуг та підрядників (разом –
          «Підрядники»), що допомагають надавати якісні Послуги. Наші Підрядники
          можуть мати обмежений доступ до Вашої Персональної інформації під час
          надання нам своїх продуктів або послуг, щоб ми, в свою чергу, могли
          надавати Вам свої Послуги. До складу цих Підрядників можуть входити
          постачальники, які надають нам технології, послуги та/або контент,
          пов'язаний з експлуатацією та технічним обслуговуванням Сайту. Доступ
          до Вашої Персональної інформації цими Підрядниками обмежений
          інформацією, обґрунтовано необхідною для того, щоб Підрядник виконував
          для нас свою обмежену функцію;
        </p>
        <p className="privacy__par">
          • на запити уповноважених органів державної влади, правоохоронних
          органів, у зв'язку з судовими процесами, з метою встановити або
          реалізувати наші законні права або захистити наше майно; для захисту
          від юридичних вимог; або як це передбачено законом;  <br />•
          правонаступникам у зв'язку з продажем або реорганізацією (злиттям,
          приєднанням, поділом, перетворенням) всього власного капіталу, бізнесу
          чи активів BroDraw; <br />• працівникам, керівникам, партнерам
          BroDraw, в обсязі та цілях, визначених в Політиці. BroDraw не буде
          розкривати Вашу Персональну інформацію третім особам, за винятком
          випадків, зазначених в Політиці.
        </p>
        <span className="privacy__par-span">
          Міжнародні передачі персональної інформації
        </span>
        <p className="privacy__par">
          Сайт BroDraw в основному експлуатується та управляється на серверах,
          розташованих в межах України. Разом з тим, інформація, яку BroDraw
          збирає, може зберігатися, оброблятися та передаватися між будь-якими
          країнами світу, в яких BroDraw здійснює свою діяльність, з метою
          якісного надання Вам наших Послуг, а також надання BroDraw можливості
          використовувати цю інформацію у відповідності з Політикою.
        </p>
        <p className="privacy__par">
          Персональна інформація, яку Ви розміщуєте на Сайті, через Інтернет,
          може бути доступна в усьому світі, в тому числі в країнах, які можуть
          не забезпечувати режим захисту Вашої Персональної інформації. Ми
          прагнемо захистити конфіденційність Персональної інформації при її
          передачі. Якщо Ви проживаєте або перебуваєте в межах Європейської
          економічної зони (ЕЕА), і такі передачі відбуваються, ми вживаємо
          відповідних заходів, щоб забезпечити такий самий рівень захисту для
          обробки, як ви мали б у межах ЕЕА, наскільки це можливо згідно з
          чинним законодавством.
        </p>
        <p className="privacy__par">
          Ми не можемо перешкодити використанню Вашої Персональної інформації,
          опублікованої Вами у всесвітній мережі Інтернет через Сайт, або
          неправомірному її використанню сторонніми особами.
        </p>
        <h2 className="privacy__subtitle">
          V. Зберігання Персональної інформації
        </h2>
        <p className="privacy__par">
          Персональна інформація, яку ми обробляємо з певною метою або в певних
          цілях не повинна зберігатися довше, ніж потрібно для досягнення цієї
          мети або цих цілей.
        </p>
        <p className="privacy__par">
          Ваша Персональна інформація буде видалена одразу після направлення
          Вами відповідного повідомлення з запитом на видалення облікового
          запису або після спливу строку, визначеного чинним законодавством.
        </p>
        <h2 className="privacy__subtitle">
          VІ. Захист Персональної інформації
        </h2>
        <p className="privacy__par">
          Ми будемо вживати достатні технічні та організаційні заходи для
          попередження втрати, протиправного використання чи підробки Вашої
          Персональної інформації, і не будемо передавати її третім особам, за
          винятком випадків, передбачених цією Політикою.
        </p>
        <p className="privacy__par">
          Оскільки Інтернет не є 100% захищеним середовищем, BroDraw не може
          гарантувати захист даних, надісланих через мережу Інтернет, і існує
          певний ризик, що несанкціонована третя сторона може знайти спосіб
          обійти наші системи безпеки або що передача Вашої Персональної
          інформації через Інтернет буде перехоплена. 
        </p>
        <p className="privacy__par">
          Ви несете відповідальність за захист Вашої Персональної інформації для
          входу в обліковий запис на Сайті. Зверніть увагу, що повідомлення
          електронної пошти, як правило, не шифруються, і їх не слід вважати
          захищеними.
        </p>
        <p className="privacy__par">
          Всю надану Вами Персональну інформацію BroDraw буде зберігати на наших
          захищених (як паролем, так і фаєрволами) серверах, при цьому вони
          можуть бути передані та зберігатися за межами України. 
        </p>
        <p className="privacy__par">
          Всі електронні фінансові транзакції, здійснені за допомогою нашого
          Сайту, будуть захищені технологією шифрування даних.
        </p>
        <h2 className="privacy__subtitle">
          VІІ. Ваші права щодо Персональної інформації
        </h2>
        <p className="privacy__par">
          Ви можете запитати у BroDraw будь-яку Персональну інформацію про Вас,
          яку ми маємо, та отримати її копію безкоштовно (крім випадків, коли
          законодавство дозволяє стягнення плати). BroDraw залишає за собою
          право відмовити в наданні інформації за Вашим запитом, в межах чинного
          законодавства.
        </p>
        <p className="privacy__par">
          Ви можете надати нам вказівку не обробляти Вашу Персональну інформацію
          в маркетингових цілях, задля чого проставити відповідну відмітку у
          запропонованій формі згоди або повідомивши нас на
          hello@brodraw.com.ua.
        </p>
        <p className="privacy__par">
          Ви можете отримати доступ до своєї Персональної інформації та
          підтвердити, що вона залишається правильною та актуальною, вибрати, чи
          хочете Ви отримувати від нас матеріали. Зазначені права можуть бути
          обмежені з метою захисту прав та свобод інших осіб.
        </p>
        <p className="privacy__par">
          Якщо Ви хочете отримати додаткову інформацію стосовно своїх прав або
          хочете скористатися будь-яким із них, ви також можете зв'язатися з
          нами через hello@brodraw.com.ua.
        </p>
        <p className="privacy__par">
          Якщо Ви проживаєте або перебуваєте в ЕЕА, Ви маєте право вимагати від
          нас: <br />
          • забезпечити доступ до будь-якої особистої інформації, яку ми
          зберігаємо про Вас;
          <br />
          • запобігати обробці Вашої Персональної інформації для цілей прямого
          маркетингу;
          <br />
          • оновлювати будь-яку Персональну інформацію, яка застаріла або
          неправильна;
          <br />
          • видалити будь-яку Персональну інформацію, яку ми зберігаємо про Вас
          («право бути забутим»);
          <br />
          • обмежити спосіб обробки Вашої Персональної інформації; • надавати
          інформацію, що ідентифікує особу, сторонньому постачальнику послуг;
          або
          <br />• надати Вам копію будь-якої Персональної інформації, яку ми
          зберігаємо про Вас.
        </p>
        <p className="privacy__par">
          Ми намагаємось відповісти на будь-які електронні листи негайно, де це
          можливо, і надаємо свою відповідь протягом періоду, визначеного чинним
          законодавством. Однак, просимо врахувати, що в наших базах даних,
          журналах доступу та інших записах буде залишкова інформація, яка може
          містити або не містити Вашу Персональну інформацію.
        </p>
        <p className="privacy__par">
          Коли Ви надсилаєте нам електронний лист із запитом, ми можемо
          попросити Вас надати нам інформацію, необхідну для підтвердження Вашої
          особи.
        </p>
        <h2 className="privacy__subtitle">
          VІІІ. Заборона збирання інформації від дітей до 14 років
        </h2>
        <p className="privacy__par">
          BroDraw твердо вірить у захист приватного життя дітей. Будь-яке
          використання або доступ до Сайту осіб віком до 14 років заборонено, а
          деякі сторінки Сайту та Послуги можуть мати додаткові вимоги та/або
          обмеження. Відповідно до цього переконання, BroDraw не збирає та не
          зберігає Персональну інформацію, що ідентифікує особу, на нашому Сайті
          від осіб, які не досягли 14-річного віку. 
        </p>
        <p className="privacy__par">
          Якщо Ви не досягли 14-річного віку, будь ласка, не використовуйте та
          не відвідуйте цей Сайт у будь-який час або будь-яким способом.
        </p>
        <p className="privacy__par">
          BroDraw, дізнавшись про існування Персональної інформації про осіб
          віком до 14 років, застосовуватиме належні заходи для видалення такої
          інформації, що була зібрана на Сайті без підтвердженої згоди батьків,
          дізнавшись про існування такої Персональної інформації.
        </p>
        <h2 className="privacy__subtitle">
          ІХ. Запитання, пропозиції та скарги
        </h2>
        <p className="privacy__par">
          Якщо у Вас є запитання, пропозиції, невирішені проблеми або скарги,
          пов'язані з конфіденційністю, ви можете зв'язатися з нами через
          hello@brodraw.com.ua.
        </p>
      </div>
    </section>
  );
}

export default Privacy;
